import React from "react";
import NoraForm from "../../components/NoraForm";
import "./style.css";

const mockInterviewerZoom = () => {
  return (
    <div className="mock-interviewer-zoom">
        <NoraForm />
    </div>
  );
};

export default mockInterviewerZoom;
