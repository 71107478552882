import React, { useEffect, useState } from 'react'
import Images from "../../Images";
import "./styles.css";
import ResetPasswordComplete from "./ResetPasswordComplete"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import $ from "jquery";
import { useNavigate } from 'react-router-dom';

const ResetPasswordForm = () => {
    let navigate = useNavigate();

    const resetPasswordEndpoint = process.env.REACT_APP_RESET_PASSWORD_ENDPOINT;

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const token = decodeURIComponent(queryParams.get("token").replace(/ /g, "+"));
    const userId = queryParams.get("userId");

    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const [hidePassword, setHidePassword] = useState("false");
    const [hideConfirmPassword, setHideConfirmPassword] = useState("false");

    const [hasResetFormSubmitted, setHasResetFormSubmitted] = useState(false);

    const [hasEightChars, setHasEightChars] = useState(false);
    const [containNums, setContainNums] = useState(false);
    const [containUpperCase, setContainUpperCase] = useState(false);
    const [containLowerCase, setContainLowerCase] = useState(false);
    const [containNonAlphaNumeric, setContainNonAlphaNumeric] = useState(false);
    const [isPasswordMatched, setIsPasswordMatched] = useState(false);
    const [isResetBtnDisabled, setIsResetBtnDisabled] = useState(true);

    const handlePasswordShowHide = (e) => {
        e.preventDefault();
        if (hidePassword === "false") {
          setHidePassword("true");
        } else {
          setHidePassword("false");
        }
    };

    const handleConfirmPasswordShowHide = (e) => {
      e.preventDefault();
      if (hideConfirmPassword === "false") {
        setHideConfirmPassword("true");
      } else {
        setHideConfirmPassword("false");
      }
    };

    const handlePasswordRestraintCheck = (e) => {
      if (e.target.value.length < 8) {
        setHasEightChars(false);
      } else {
        setHasEightChars(true);
      }

      const regexForNum = /\d/;
      const regexForUpperCase = /[A-Z]/;
      const regexForLowerCase = /[a-z]/;
      const regexForNonAlphaNumeric = /[^a-zA-Z0-9]/g;

      if (regexForNum.test(e.target.value)) {
        setContainNums(true);
      } else {
        setContainNums(false);
      }

      if (regexForUpperCase.test(e.target.value)) {
        setContainUpperCase(true);
      } else {
        setContainUpperCase(false);
      }

      if (regexForLowerCase.test(e.target.value)) {
        setContainLowerCase(true);
      } else {
        setContainLowerCase(false);
      }

      if (regexForNonAlphaNumeric.test(e.target.value)) {
        setContainNonAlphaNumeric(true);
      } else {
        setContainNonAlphaNumeric(false);
      }

      if (e.target.value !== passwordConfirm) {
        setIsPasswordMatched(false)
      } else {
        setIsPasswordMatched(true)
      }
  
      setPassword(e.target.value);
    }

    const handlePasswordConfirm = (e) => {
      if (e.target.value !== password) {
        setIsPasswordMatched(false)
      } else {
        setIsPasswordMatched(true)
      }

      setPasswordConfirm(e.target.value)
    }

    const handleResetPassword = (e) => {
      e.preventDefault();

      $.ajaxSetup({
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
  
      let m = $.post(
        `${resetPasswordEndpoint}`,
        JSON.stringify({ Password: password, ConfirmPassword: passwordConfirm, UserId: userId, Token: token}),
        (data) => {
          setHasResetFormSubmitted(true);
        }
      ).fail((data2) => {
        if (data2.status === 400) {
          console.log(data2)
        }
      });
    };

    useEffect(() => {
      if (hasEightChars && containNums && containUpperCase && containLowerCase && containNonAlphaNumeric && isPasswordMatched) {
        setIsResetBtnDisabled(false)
      } else {
        setIsResetBtnDisabled(true)
      }
    }, [hasEightChars, containNums, containUpperCase, containLowerCase, containNonAlphaNumeric, isPasswordMatched])

  return (
    <div
      className="bg-img"
      style={{
        backgroundImage: `url(${Images.BACKGROUND})`,
        backgroundRepeat: "repeat-y",
        backgroundSize: "cover",
        minWidth: "100vw",
        minHeight: "100vh",
      }}>

        <img
        className="ig-logo"
        src={Images.IG_LOGO2}
        alt="Intern guys"
        onClick={() => navigate('/')} />

        {
          hasResetFormSubmitted ? (
            <div>
              <ResetPasswordComplete />
            </div>
          ) : (
              <div className='password-reset-form'>
                <h1 className='reset-pass-form-title'>Reset Your Password</h1>
                <form className="ml-0">
                    <div className="password-confirm-input-div">
                      <input
                            type={hidePassword === "false" ? "password" : "text"}
                            className="form-control forgot-pass__text-input mt-2 password-confirm-input"
                            placeholder="Password"
                            value={password}
                            required
                            onChange={(e) => handlePasswordRestraintCheck(e)}
                          />
                      <img
                          className="eyeicon"
                          src={
                            hidePassword === "false" ? Images.EYE_CLOSED : Images.EYE_OPEN
                          }
                          alt="Instagram"
                          onClick={(e) => handlePasswordShowHide(e)}
                        />
                    </div>
                    <div className="password-restraint-div">
                      <div className="password-restraint-1">
                        <div>
                          { !hasEightChars ? <FontAwesomeIcon icon={faCircle} /> : <FontAwesomeIcon icon={faCheckCircle} color='green' />}
                          <span className="password-restraint-text">At least 8 characters</span>
                        </div>
                        
                        <div>
                          { !containNums ? <FontAwesomeIcon icon={faCircle} /> : <FontAwesomeIcon icon={faCheckCircle} color='green' /> }
                          <span className="password-restraint-text">Contains numbers</span>
                        </div>
                      </div>
                      <div className='password-restraint-2'>
                        <div>
                          { !containUpperCase ? <FontAwesomeIcon icon={faCircle} /> : <FontAwesomeIcon icon={faCheckCircle} color='green' /> }
                          <span className="password-restraint-text">Contains uppercase letters</span>
                        </div>
                        <div>
                          { !containLowerCase ? <FontAwesomeIcon icon={faCircle} /> : <FontAwesomeIcon icon={faCheckCircle} color='green' /> }
                          <span className="password-restraint-text">Contains lowercase letters</span>
                        </div>
                      </div>
                      <div className='password-restraint-3'>
                        <div>
                          { !containNonAlphaNumeric ? <FontAwesomeIcon icon={faCircle} /> : <FontAwesomeIcon icon={faCheckCircle} color='green' /> }
                          <span className="password-restraint-text">Contains non-alphanumeric</span>
                        </div>
                        <div>
                          { !isPasswordMatched ? <FontAwesomeIcon icon={faCircle} /> : <FontAwesomeIcon icon={faCheckCircle} color='green' /> }
                          <span className="password-restraint-text">Password matched</span>
                        </div>
                      </div>
                    </div>
                    <div className="password-confirm-input-div">
                      <input
                        placeholder="Confirm Password"
                        className="form-control forgot-pass__text-input mt-2 password-confirm-input"
                        type={hideConfirmPassword === "false" ? "password" : "text"}
                        value={passwordConfirm}
                        required
                        onChange={handlePasswordConfirm}
                      />
                      <img
                          className="eyeicon"
                          src={
                            hideConfirmPassword === "false" ? Images.EYE_CLOSED : Images.EYE_OPEN
                          }
                          alt="Instagram"
                          onClick={(e) => handleConfirmPasswordShowHide(e)}
                        />
                    </div>
                    {
                      isResetBtnDisabled ? (
                        <div className="d-flex forgot-pass__reset-btn-div">
                          <button 
                            disabled
                            className="forgot-pass_reset-btn-disabled"
                            onClick={handleResetPassword}>
                              Reset Password
                          </button>
                        </div>
                      ) : (
                        <div
                          className="d-flex forgot-pass__reset-btn-div">
                          <button 
                            className="forgot-pass_reset-btn"
                            onClick={handleResetPassword}>
                              Reset Password
                          </button>
                        </div>
                      )
                    }
                    
                </form>
              </div>
          )
        }
    </div>
  )
}

export default ResetPasswordForm