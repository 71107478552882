import React from 'react';
import './style.css';
import Images from "../../Images";
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { FaTiktok } from 'react-icons/fa';
import PrivacyPolicy from '../../assets/Legal/PrivacyPolicy.pdf'
import TermsofService from '../../assets/Legal/TermsofService.pdf'
import { useLocation } from "react-router-dom";

const Footer = (props) => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  
  return (
    <footer className={`homepage_footer ${isHomePage ? 'other' : 'home'}`}>
      <div className="footer-line"></div>
      <div className="footer-content">
        <div className="footer-logo-container">
          <img
            src={location.pathname === '/' ? Images.IG_LOGO2 : Images.IG_LOGO}
            alt="Intern guys"
            className="footer_logo"
          />
        </div>
        <div className="footer-content-columns-contain">
          <div className="footer-content-columns">
            <h3>For Students</h3>
            <p><a href="https://www.internguys.com/">Home</a></p>

          </div>
          <div className="footer-content-columns">
            <h3>For Recruiters</h3>
            <p> <a href="https://recruiter.internguys.com/">Home</a></p>
            <p> <a href="https://recruiter.internguys.com/howItWorks">How It Works </a></p>
          </div>
          <div className="footer-content-columns">
            <h3>Company</h3>
            <p> <a href="https://www.internguys.com/about">About Us</a></p>
            <p> <a href="https://www.internguys.com/press ">Press </a></p>
            <p> <a href="https://www.internguys.com/contact">Contact</a></p>
            <p><a href="https://www.internguys.com/blog">Blog</a></p>

          </div>
          <div className="footer-social-icons-small footer-content-columns">
            <a href="https://www.instagram.com/internguys/?hl=en" target="_blank" rel="noreferrer">
              <InstagramIcon  className="sc-icons"/>
            </a>
            <a href="https://www.linkedin.com/company/intern-guys/" target="_blank" rel="noreferrer">
              <LinkedInIcon className="sc-icons"/>
            </a>
            <a href="https://www.tiktok.com/@internguys" target="_blank" rel="noreferrer">
              <FaTiktok className="sc-icons"/>
            </a>
          </div>
        </div>


      </div>
      <div className="footer-links">
        <div className="footer-doc">
          <div className="footer-doc-txt">
            <p><strong>Intern Guys.</strong></p>
            <p className="right-text">All Rights Reserved</p>
          </div>
          <a href = {PrivacyPolicy} target = "_blank">Privacy Policy</a>
          <a href = {TermsofService} target = "_blank">Terms of Service</a>
        </div>
        <div className="footer-social-icons">
          <a href="https://www.instagram.com/internguys/?hl=en" target="_blank" rel="noreferrer">
            <InstagramIcon  className="sc-icons"/>
          </a>
          <a href="https://www.linkedin.com/company/intern-guys/" target="_blank" rel="noreferrer">
            <LinkedInIcon className="sc-icons"/>
          </a>
          <a href="https://www.tiktok.com/@internguys" target="_blank" rel="noreferrer">
            <FaTiktok className="sc-icons"/>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
