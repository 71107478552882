import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './style.css';
import { ReactMic } from 'react-mic';
import { useNavigate } from "react-router-dom";
import IconZoom from "../../images/icon-zoom.png";
import PersonZoom from "../../images/person-zoom.png";
import LeftArrow from "../../images/homepage/arrow-left.svg";
import VideoTimer from "../../images/video-timer.png";
import VideoOn from "../../images/video.png";
import VideoOff from "../../images/video-off.png";
import { Oval } from 'react-loader-spinner'; 
import { AssemblyAI } from 'assemblyai';
import ExclamationOutline from "../../images/exclamation.png";

const NoraForm = () => {
  const [started, setStarted] = useState(false);
  const [history, setHistory] = useState([]);
  const [record, setRecord] = useState(false);
  const [isVideoOn, setIsVideoOn] = useState(false);
  const [time, setTime] = useState(0);
  const videoRef = useRef(null);
  const messageEndRef = useRef(null);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNoraSpeaking, setIsNoraSpeaking] = useState(false);
  const [isInterviewDone, setInterviewDone] = useState(false);
  const [aiResponse, setAiResponse] = useState('');

  const position = localStorage.getItem("position");
  const jobDescription = localStorage.getItem("jobDescription");
  let navigate = useNavigate();

  const client = new AssemblyAI({
    apiKey: "d1ed33af12ac4d478785ac40bb4599a7"
  });

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [history]);

  useEffect(() => {
    console.log("aiResponse: ", aiResponse);
  }, [aiResponse]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(prevTime => prevTime + 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const m = Math.floor(seconds / 60);
    const s = seconds % 60;
    return `${m}m ${s < 10 ? '0' : ''}${s}sec`;
  };

  const startRecording = () => {
    setRecord(true);
  };

  const stopRecording = () => {
    setRecord(false);
  };

  const onBackClick = () => {
    navigate("/mockInterviewer");
    window.location.reload();
  };

  //This will retry the API twice if it fails for some reason in the first time
  const retryRequest = async (fn, retries = 2, interval = 1000) => {
    for (let attempt = 0; attempt < retries; attempt++) {
      try {
        return await fn();
      } catch (error) {
        if (attempt < retries - 1) {
          await new Promise(resolve => setTimeout(resolve, interval));
        } else {
          throw error;
        }
      }
    }
  };

  const handleEndInterview = async () => {
    if(isVideoOn) {
      toggleVideo();
    }

    setFeedbackLoading(true);
    const formData = new FormData();
    formData.append('position', position);
    formData.append('jobDescription', jobDescription);

    const resumeDataUrl = localStorage.getItem("resume");
    var file = new Blob();

    if (resumeDataUrl) {
      const base64String = resumeDataUrl.split(',')[1];
      const mimeType = resumeDataUrl.split(',')[0].split(':')[1].split(';')[0];

      // Create a Blob from the Base64 string
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      file = new Blob([byteArray], { type: mimeType });

      formData.append('resume', file);
    }

    setHistory(prevHistory => {
      const newHistory = [...prevHistory, { role: 'User', message: "last" }];    
      formData.append('History', JSON.stringify(newHistory));
      return newHistory;
    });

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/Chat/generateFeedback`, {
        Position: position,
        JobDescription: jobDescription,
        History: history,
        Resume: file
      }, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const aiResponse = response.data.feedback;
      const timerFeedback = formatTime(time);
      navigate("/mockInterviewerFeedback", { state: { aiResponse, history, timerFeedback } });

    } catch (error) {
      console.error('Error sending message:', error);
    }
    setFeedbackLoading(false);
  };

  const onStop = (recordedBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(recordedBlob.blob);
    assemblySpeechToText(recordedBlob.blob);
  };

  const assemblySpeechToText = async (audioBlob) => {
    setIsLoading(true);
    const transcript = await client.transcripts.transcribe({
      audio: audioBlob
    });

    if (transcript.status === 'error') {
      console.log("Error transcribing audio", transcript.error);
    }

    handleSendMessage(transcript.text);
  }

  const toggleVideo = async () => {
    if (isVideoOn) {
      let stream = videoRef.current.srcObject;
      let tracks = stream.getTracks();

      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    } else {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        videoRef.current.srcObject = stream;
      } catch (err) {
        console.error("Error accessing webcam: ", err);
      }
    }
    setIsVideoOn(!isVideoOn);
  };

  const handleSendMessage = async (transcription) => {
    const formData = new FormData();

    const resumeDataUrl = localStorage.getItem("resume");

    if (resumeDataUrl) {
      const base64String = resumeDataUrl.split(',')[1];
      const mimeType = resumeDataUrl.split(',')[0].split(':')[1].split(';')[0];

      // Create a Blob from the Base64 string
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const file = new Blob([byteArray], { type: mimeType });

      formData.append('resume', file);
    }

    formData.append('position', position);
    formData.append('jobDescription', jobDescription);
    formData.append('UserMessage', transcription);

    setHistory(prevHistory => {
      const newHistory = [...prevHistory, { role: 'User', message: transcription }];    
      formData.append('History', JSON.stringify(newHistory));
      return newHistory;
    });

    try {
      const response = await retryRequest(() => axios.post(`${process.env.REACT_APP_API_ENDPOINT}/Chat/nora`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }));
      const { response: aiResponse } = response.data;

      setAiResponse(aiResponse);

      const audioResponse = await retryRequest(() => axios.post(`${process.env.REACT_APP_API_ENDPOINT}/Chat/textToSpeech`, {
        text: aiResponse
      }, {
        responseType: 'blob'
      }));

      if (aiResponse.toLowerCase().includes("end of the interview")) {
        setInterviewDone(true);
      }

      if (audioResponse.status === 200) {
        const audioBlob = new Blob([audioResponse.data], { type: 'audio/mp3' });
        const audioUrl = URL.createObjectURL(audioBlob);

        const audio = new Audio(audioUrl);
        setIsNoraSpeaking(true);
        audio.play();
        audio.onended = () => {
          setIsNoraSpeaking(false);
        };
      } else {
        console.error('Failed to convert text to speech');
      }

      setHistory(prevHistory => [
        ...prevHistory,
        { role: 'Assistant', message: aiResponse },
      ]);
    } catch (error) {
      console.error('Error sending message:', error);
    }
    setIsLoading(false);
  };  

  const startInterview = () => {
    setStarted(true);
    setIsLoading(true);
    handleSendMessage("first");
  };

  return (
    <div className="nora-form">
      {!started ? (
        <div className="instructions">
          <h1>Instructions</h1>
          <p className="instructions-intro">Our AI Mock Interviewer Nora will ask you questions, provide a transcript of your answers, and analyze your interview afterward.</p>
          <div className="instruction-warning">
            <img src={ExclamationOutline} alt="Warning" className="exclamation-icon" />
            <p>
              PLEASE CONDUCT INTERVIEWS IN A NOISE-FREE ENVIRONMENT.
              DURING THE INTERVIEW, <br /> CLICK <strong>'START'</strong> TO BEGIN ANSWERING A QUESTION AND <strong>'STOP'</strong> TO FINISH ANSWERING.
            </p>
          </div>
          <button onClick={startInterview} className="start-button">Start</button>
        </div>
      ) : (
        <>
          {feedbackLoading && (
            <div className="loading-overlay">
              <Oval color="#00BFFF" height={80} width={80} />
              <div className="loading-text">Generating Feedback for Your Interview (~2 minutes)</div>
            </div>
          )}
          <div className="controls-section">
            <div className="position-display">
              <img
                src={LeftArrow}
                onClick={onBackClick}
                className="left-arrow-styling"
              />
              <span className="position-text">{position}</span>
              <div className="timer-display">
                <img src={VideoTimer} className="video-timer-styling"/>
                {formatTime(time)}
              </div>
            </div>
            <img className="ig-zoom" src={IconZoom} alt="Intern guys logo"/>
            <div className="controls">
              <div className="action-buttons">
                <img src={isVideoOn ? VideoOff: VideoOn} onClick={toggleVideo} className="video-on-styling"/>
                <button disabled={(!record && (isLoading || isNoraSpeaking || isInterviewDone))} onClick={record ? stopRecording : startRecording} 
                  type="button" 
                  className={`recordBtn round-btn ${record ? 'stop-recording' : ''} ${(!record && (isLoading || isNoraSpeaking || isInterviewDone)) ? 'disabled' : ''}`}>
                  {record ? 'Stop Answering' : 'Start Answering'}
                </button>
                <div className="video-container">
                  <div className="video-wrapper">
                    <video ref={videoRef} autoPlay className={`video-feed ${isVideoOn ? '' : 'hidden'}`}></video>
                    {!isVideoOn && <img className="person-zoom" src={PersonZoom} alt="Video of user"/>}
                    <div className="audio-visualizer">
                      <ReactMic
                        record={record}
                        className="mic-styling"
                        onStop={onStop}
                        visualSetting="frequencyBars"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="transcript-container">
            <h2 style={{ textAlign: 'center' }}>Live Transcript</h2>
            <div className="message-history">
              {history.map((msg, index) => (
                msg.message !== "first" && msg.message !== "last" && (
                  <div key={index} className={`message ${msg.role}`}>
                    {msg.message}
                  </div>
                )
              ))}
              {isLoading && (
                <div className="">
                  <Oval color="#00BFFF" height={20} width={20} />
                  <span>Nora is thinking...</span>
                </div>
              )}
              <div ref={messageEndRef} />
            </div>
            <button onClick={handleEndInterview} type="button" className="endInterviewBtn">End Interview</button>
          </div>
        </>
      )}
    </div>
  );
};

export default NoraForm;