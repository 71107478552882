import $ from "jquery";
import { storeToken } from "./loginState";

export function onSignInGoogle(googleUser) {
  const token = googleUser.getAuthResponse().id_token;
  // const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
  const googleAuthEndPoint = process.env.REACT_APP_GOOGLE_AUTH_ENDPOINT;

  $.ajaxSetup({
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  $.post(
    `${googleAuthEndPoint}`,
    JSON.stringify({ idTokenString: token }),
    (data) => {
      console.log(data);
      //Use token in data to make API calls
      localStorage.setItem("userEmail","placeholderValue");
      storeToken(data);
      window.location.replace("/internshipPreferenceQuestions");
      googleUser.disconnect();
    }
  );
}
export function onLoginInGoogle(googleUser) {
  const token = googleUser.getAuthResponse().id_token;
  // const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
  const googleAuthEndPoint = process.env.REACT_APP_GOOGLE_AUTH_ENDPOINT;

  $.ajaxSetup({
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  $.post(
    `${googleAuthEndPoint}`,
    JSON.stringify({ idTokenString: token }),
    (data) => {
      console.log(data);
      //Use token in data to make API calls
      localStorage.setItem("userEmail","placeholderValue");
      storeToken(data);
      window.location.replace("/");
      googleUser.disconnect();
    }
  );
}
