import React, {useState, useEffect, useRef} from 'react';
import './styles.css';
import 'react-multi-carousel/lib/styles.css';
import Footer from '../../components/Footer';
import { useNavigate } from 'react-router-dom';

import FINVEST from '../../images/homepage/finvest.png';
import CRADLEWISE from '../../images/homepage/cradlewise.png';
import MERLIN from '../../images/homepage/merlin.png';
import NUTRIPAIR from '../../images/homepage/nutripair.png';
import LIMON from '../../images/homepage/limon.png';
import DONUT from '../../images/homepage/donut.png';
import KEYZII from '../../images/homepage/keyzii.png';
import LOCKERVERSE from '../../images/homepage/lockerverse.png';
import SHADES from '../../images/homepage/shadesNews.png';
import LEAPLABS from '../../images/homepage/leaplabs.png';
import QUILL from '../../images/homepage/quill.png';
import FORTE from '../../images/homepage/forte.png';
import VIRUFY from '../../images/homepage/virufy.png';
import BLUEPOND from '../../images/homepage/bluepond.png';
import NEOBOARD from '../../images/homepage/neoboard.png';
import NIRAXX from '../../images/homepage/niraxx.png';
import TOKKITENNIS from '../../images/homepage/tokkisports.png';
import PETE from '../../images/homepage/peteLearning.png';
import SIMPLECITI from '../../images/homepage/simpleCiti.png';

import HOWITWORKS1 from '../../images/homepage/howitworks1.png';
import HOWITWORKS2 from '../../images/homepage/howitworks2.png';
import HOWITWORKS3 from '../../images/homepage/howitworks3.png';

import FILEUPLOADED from "../../images/homepage/fileUploaded.png";
import FILENOTUPLOADED from "../../images/homepage/fileNotUploaded.png";

import 'swiper/swiper-bundle.css';
import 'swiper/css/pagination';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import { InlineWidget } from 'react-calendly';
import { useMediaQuery } from '@mui/material';

import { getToken } from '../../authetication/loginState';

import Review1 from "../../images/homepage/review1.png";
import Review2 from "../../images/homepage/review2.png";
import Review3 from "../../images/homepage/review3.png";
import Review4 from "../../images/homepage/review4.png";

SwiperCore.use([Pagination]);

function Homepage({ loginState }) {
    let navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const generateTokenEndpoint = process.env.REACT_APP_GENERATE_TOKEN_ENDPOINT;

    const [resumeUploaded, setResumeUploaded] = useState(false);
    const [file, setFile] = useState(null);

    const [tempToken, setTempToken] = useState("");
    const [fileName, setFileName] = useState("");
    const [loading, setLoading] = useState(false);

    const scrollToComponentCheetah = () => {
        document.getElementById('cheetah_mode').scrollIntoView({ behavior: 'smooth' });
      }; 

    const generateGuestToken = async () => {
        const response = await fetch(`${generateTokenEndpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    
        if (!response.ok) {
            throw new Error('Failed to generate guest token');
        }
    
        const data = await response.json();
        return data.token;
    };

    const handleFileUpload = (e) => {
        const uploadedFile = e.target.files[0];
        setFileName(uploadedFile.name);
        setFile(uploadedFile);
        if (uploadedFile) {
            setResumeUploaded(true);
        } else {
            setResumeUploaded(false);
        }
    };

    const applyInternship = async (e) => {
        setLoading(true);
        window.scrollTo(0, 0);
        e.preventDefault();
    
        const errorBanner = document.getElementById("errorBanner");
        if (errorBanner) {
            errorBanner.style.display = "none";
        }
    
        const applyInternshipData = {
            internshipId: '3a095a00-4d23-4ec1-9b3a-1be47a462a7a',
            userId: '22dce5bb-67d3-43c5-a2f8-a1624017c447', // Default user ID
            firstName: 'Default',
            lastName: 'commonApp',
            city: 'City',
            country: 'Country',
            phoneNumber: '6692042381',
            email: 'commonApp@gmail.com',
            resumeFileName: file.name,
        };
    
        const fd = new FormData();
        if (file) {
            fd.append("file", file);
        }
    
        let token = getToken();
    
        if (!token) {
            try {
                token = await generateGuestToken();
                setTempToken(token);
            } catch (error) {
                console.error("Error:", error);
                if (errorBanner) {
                    errorBanner.style.display = "block";
                }
                return;
            }
        } else {
            setTempToken(token);
        }
        
        const headers = {
            'Content-Type': 'application/json',
            ...(token && { Authorization: `Bearer ${token}` })
        };
    
        try {
            const applyResponse = await fetch(`${process.env.REACT_APP_APPLY_INTERNSHIP}`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(applyInternshipData)
            });
    
            if (!applyResponse.ok) {
                throw new Error('Failed to apply for internship');
            }
    
            const data = await applyResponse.json();
            const applicationId = data.studentAppliedIdReturn;
    
            const uploadHeaders = token ? { Authorization: `Bearer ${token}` } : {};
    
            const uploadResponse = await fetch(
                `${process.env.REACT_APP_ATTACH_RESUME}?student_applied_id=${applicationId}`,
                {
                    method: "POST",
                    headers: uploadHeaders,
                    body: fd,
                }
            );
    
            if (uploadResponse.status === 200) {
                alert("Cheetah Apply Successful!");
                window.location.reload();
            } else {
                alert("Cheetah Apply Unsuccessful");
                window.location.reload();
            }
        } catch (error) {
            console.error("Error:", error);
            if (errorBanner) {
                errorBanner.style.display = "block";
            }
        }
        setLoading(false);
    };       

    const reviews = [
        {
            words: 'Great resource for students trying to get into the professional world. The whole process of creating a resume, finding open positions, and applying was exactly what I needed to be done.',
            person: 'Daniella Park.',
            position: 'Computer Engineer Major, Sophomore',
        },
        {
            words: 'Easiest and honestly the most fun platform out there to get an internship! Since its a student started platform Intern Guys knows exactly what can help us land an internship. Must try!',
            person: 'Benjamin S.',
            position: 'Business Economics Major, Junior',
        },
        {
            words: 'Intern Guys got some great internship companies to work for! I think if this is your first time trying for an internship - you should go with the companies Intern Guys has here! Great places to learn!',
            person: 'Terry Liam.',
            position: 'Computer Science Major, Junior',
        },
        {
            words: 'Intern Guys makes it simple to apply to internships with everything you need in one website. Its just create a resume, search for internships, and apply!',
            person: 'Crystal Lai-Ton-Nu.',
            position: 'Informatics Major, Senior',
        },
        {
            words: 'I really like the search feature Intern Guys provides. Their match making algorithm provides me with positions best catered to me with percentages! Lets say you have 90% match for a position, they even let you know the other 10% where you can improve!',
            person: 'Tori Song.',
            position: 'Marketing Major, Sophomore',
        },
        {
            words: 'Great website - love their UI! They have a fun, light, Netfix vibe to their website and I really vibe to it. Getting an internship doesnt have to be so serious, rather can be fun, and Intern Guys plays that card really well!',
            person: 'Ashwin Atthiappan.',
            position: 'Data Science Major, Freshman',
        },
    ]

    const internships = [
        {
            image: 'company1',
            position: 'Marketing Intern',
            company: 'Limon',
            location: 'Los Angeles, CA',
        },
        {
            image: 'company2',
            position: 'UI/UX Design Intern',
            company: 'Nutripair',
            location: 'Irvine, CA',
        },
        {
            image: 'company3',
            position: 'Software Engineer Intern',
            company: 'Virufy',
            location: 'Palo Alto, CA',
        },
    ]

    function previewRedirect() {
        if (loginState.loggedIn) {
            navigate('/Search')
        } else {
            navigate('/Login')
        }
    }

    const [openIndex, setOpenIndex] = useState(null);
    const faqRefs = useRef([]);

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    useEffect(() => {
        const isMobile = window.innerWidth <= 600;
        faqRefs.current.forEach((ref, idx) => {
            if (ref) {
                if (openIndex === idx) {
                    const contentHeight = ref.scrollHeight;
                    ref.style.maxHeight = `${contentHeight}px`;
                    
                    if (isMobile) {
                        // For mobile: start at 170px, add extra padding based on content
                        const basePadding = 165;
                        const extraPadding = Math.min(contentHeight * 0.1, 100);
                        const totalPadding = basePadding + extraPadding;
                        ref.style.padding = `0 20px ${totalPadding}px`;
                    } else {
                        // For desktop: fixed 50px padding
                        ref.style.padding = '0 20px 70px';
                    }
                } else {
                    ref.style.maxHeight = '0';
                    ref.style.padding = '0 20px';
                }
            }
        });
    }, [openIndex]);
    
    const faqs = [
    {
        question: "Are interns only for summer?",
        answer: "We provide interns year round. With a talent pool of 2.8 million students - you can be specific with your intern requirements :)"
    },
    {
        question: "How much does this cost?",
        answer: "We charge $800/intern. No other fees and we don't charge students. Also, it's fully refundable if things don't work out!"
    },
    {
        question: "How much do I pay interns?",
        answer: "Compensation of interns is up to you. We see - Pay by the hour, stipend and volunteer. But we're always team pay-the-interns!"
    },
    {
        question: "What interns can I get?",
        answer: "Pretty much anything. We have a talent pool of 2.8 million students - SWE, UI/UX, Sales, Marketing, Finance, etc. We got it all."
    }
    ];

    const [isVisible, setIsVisible] = useState(false);
    const [scrollProgress, setScrollProgress] = useState(0);

    const resetProgress = () => {
        setScrollProgress(0);
    };

    useEffect(() => {
        const toggleVisibility = () => {
            const scrolled = document.documentElement.scrollTop;
            const maxHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            const scrollPercentage = (scrolled / maxHeight) * 100;
        
            if (scrolled > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
            setScrollProgress(scrollPercentage);
        };
    
        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        resetProgress();
    };

    return (
        <div className='homepage'>
            {loading && (
                <div className="loader3">
                    <div className="loader2"></div>
                </div>
            )}
            <div className="landing">
                <div className="left-container">
                    <h1>Your All-In-One Internship Finding Solution</h1>
                    <p>The fastest way to get an internship</p>
                    {!loginState.loggedIn && (
                        <div className="button-container">
                        <a href="/login">
                            <div className="button">
                                <p>Sign Up</p>
                            </div>
                        </a>
                        <a onClick={() => scrollToComponentCheetah()}>
                            <div className="cheetah-button">
                                <p>Cheetah Apply</p>
                            </div>
                        </a>
                    </div>
                    )}
                </div>
            </div>

            {/* Logo slider */}
            <div className="logo-slider">
                <div className="trusted-text">
                Access 100+ top startups and small businesses
                </div>
                <div className="logo-track">
                {/* Repeat the logos twice for a continuous effect */}
                {[...Array(2)].map(() => (
                    <>
                    <div className="logo-item">
                        <img src={CRADLEWISE} alt="Cradlewise" />
                    </div>
                    <div className="logo-item">
                        <img src={FINVEST} alt="Finvest" />
                    </div>
                    <div className="logo-item">
                        <img src={MERLIN} alt="Merlin" />
                    </div>
                    <div className="logo-item">
                        <img src={NUTRIPAIR} alt="Nutripair" />
                    </div>
                    <div className="logo-item">
                        <img src={LIMON} alt="Limon" />
                    </div>
                    <div className="logo-item">
                        <img src={DONUT} alt="Donut" />
                    </div>
                    <div className="logo-item">
                        <img src={KEYZII} alt="Keyzii" />
                    </div>
                    <div className="logo-item">
                        <img src={LOCKERVERSE} alt="Lockerverse" />
                    </div>
                    <div className="logo-item">
                        <img src={SHADES} alt="Shades News" />
                    </div>
                    <div className="logo-item">
                        <img src={LEAPLABS} alt="Leap Labs" />
                    </div>
                    <div className="logo-item">
                        <img src={QUILL} alt="Quill" />
                    </div>
                    <div className="logo-item">
                        <img src={FORTE} alt="Forte" />
                    </div>
                    <div className="logo-item">
                        <img src={VIRUFY} alt="Virufy" />
                    </div>
                    <div className="logo-item">
                        <img src={BLUEPOND} alt="Bluepond" />
                    </div>
                    <div className="logo-item">
                        <img src={NEOBOARD} alt="Neoboard" />
                    </div>
                    <div className="logo-item">
                        <img src={NIRAXX} alt="Niraxx" />
                    </div>
                    <div className="logo-item">
                        <img src={TOKKITENNIS} alt="Tokki Tennis" />
                    </div>
                    <div className="logo-item">
                        <img src={PETE} alt="Pete Learning" />
                    </div>
                    <div className="logo-item">
                        <img src={SIMPLECITI} alt="Simple Citi Holding" />
                    </div>
                    </>
                ))}
                </div>
            </div>

            <div className="hiring-stats-container">
                <div className="stats-left">
                    <span className="stats-heading">
                        We've facilitated over 250+ intern hires across all fields
                    </span>
                    <div className="button-container">
                        <a href="/login">
                            {loginState.loggedIn ? 
                                (null) : 
                                (<div className="button">
                                    <p>Apply now</p>
                                </div>)
                            }
                        </a>
                    </div>
                </div>
                <div className="fields-slider">
                    <div className="fields-track">
                        <div className="fields-line">
                            <span className="field">UI/UX</span>
                            <span className="field">SWE</span>
                            <span className="field">Marketing</span>
                            <span className="field">Campus Ambassador</span>
                            <span className="field">Investment Banking</span>
                            <span className="field">Data Analyst</span>
                            <span className="field">Business Development</span>
                            <span className="field">PR</span>
                            <span className="field">Sales</span>
                            <span className="field">Content Marketing</span>
                            <span className="field">Electrical Engineering</span>
                            <span className="field">Data Science</span>
                            <span className="field">QA</span>
                            <span className="field">SWE Dev Relations</span>
                            <span className="field">Finance</span>
                            <span className="field">Mechanical Engineering</span>
                            <span className="field">Clinical Assistant</span>
                            <span className="field">Legal</span>
                            <span className="field">Data Science</span>
                            <span className="field">Business Operations</span>
                        </div>
                        <div className="fields-line">
                            <span className="field">Digital Marketing</span>
                            <span className="field">E-commerce</span>
                            <span className="field">Machine Learning</span>
                            <span className="field">Artificial Intelligence</span>
                            <span className="field">Blockchain</span>
                            <span className="field">Cloud Computing</span>
                            <span className="field">IT Support</span>
                            <span className="field">Network Engineering</span>
                            <span className="field">Mobile App Development</span>
                            <span className="field">Technical Writing</span>
                            <span className="field">Data Engineering</span>
                            <span className="field">Consulting</span>
                            <span className="field">Risk Management</span>
                            <span className="field">Accounting</span>
                            <span className="field">Customer Success</span>
                            <span className="field">Enterprise Software</span>
                            <span className="field">DevOps</span>
                            <span className="field">Systems Engineering</span>
                        </div>
                        <div className="fields-line">
                            <span className="field">UI/UX</span>
                            <span className="field">SWE</span>
                            <span className="field">Marketing</span>
                            <span className="field">Campus Ambassador</span>
                            <span className="field">Investment Banking</span>
                            <span className="field">Data Analyst</span>
                            <span className="field">Business Development</span>
                            <span className="field">PR</span>
                            <span className="field">Sales</span>
                            <span className="field">Content Marketing</span>
                            <span className="field">Electrical Engineering</span>
                            <span className="field">Data Science</span>
                            <span className="field">QA</span>
                            <span className="field">SWE Dev Relations</span>
                            <span className="field">Finance</span>
                            <span className="field">Mechanical Engineering</span>
                            <span className="field">Clinical Assistant</span>
                            <span className="field">Legal</span>
                            <span className="field">Data Science</span>
                            <span className="field">Business Operations</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="outsourcing-solution">
                <h2 className="outsourcing-heading">Internships made easy</h2>
                <p className="outsourcing-description">
                    Apply in 5 minutes. Guaranteed a response within 2 days. 
                </p>
            </div>

            <div className="how-it-works-1-section">
                <div className="how-it-works-1-content">
                    <h2 className="how-it-works-1-heading">Apply to positions</h2>
                    <p className="how-it-works-1-description">
                        Sign up and apply to internships in under 5 minutes. 
                        Or use <strong>Cheetah Mode</strong>: our catch-all application that applies you to all positions on the platform.
                    </p>
                </div>
                <div className="how-it-works-1-partnership">
                    <img src={HOWITWORKS1} alt="How it works 1" id="cheetah_mode" className='image1-styling'/>
                </div>
            </div>

            <div className="cheetah-mode-container">
                <div className="cheetah-mode-content">
                    <h2>Cheetah Mode</h2>
                    <p>The Internship Common App</p>
                    <p>Drop your resume here and automatically apply to <strong>every active position</strong> on the platform</p>
                    <button 
                        className="cheetah-apply-button" 
                        id="cheetah-apply-button" 
                        onClick={applyInternship} 
                        disabled={!resumeUploaded}
                        style={{
                            backgroundColor: resumeUploaded ? '#1DAF5C' : '#ccc',
                            cursor: resumeUploaded ? 'pointer' : 'not-allowed'
                        }}
                    >
                        Cheetah Apply
                    </button>
                </div>
                <div className="cheetah-mode-upload">
                    <div className="upload-box">
                        <input type="file" id="file-upload" accept=".pdf" onChange={handleFileUpload} />
                        <label htmlFor="file-upload">
                            <img src={!resumeUploaded ? FILENOTUPLOADED : FILEUPLOADED} alt="PDF Icon" />
                            {!resumeUploaded ? (
                                <>
                                    <p>Drop PDF here or Click to upload</p>
                                    <p>up to 10 MB</p>
                                </>
                            ) : (
                                <>
                                    <p>{fileName}</p>
                                </>
                            )}
                        </label>
                    </div>
                </div>
            </div>

            <div className="how-it-works-2-section">
                <div className="how-it-works-2-content">
                    <div className="how-it-works-2-text">
                        <h2 className="how-it-works-2-heading">Hear back in 2 days</h2>
                        <p className="how-it-works-2-description">
                            You are guaranteed a response in 2 days informing whether you've received an interview or not.
                        </p>
                    </div>
                    <div className="how-it-works-2-partnership">
                        <img src={HOWITWORKS2} alt="How it works 2" className='image2-styling'/>
                    </div>
                </div>
            </div>

            <div className="how-it-works-3-section">
                <div className="how-it-works-3-content">
                <div>
                    <h2 className="how-it-works-1-heading">It's not over yet</h2>
                    <p className="how-it-works-3-description">
                    We collaborate with numerous startups for intern placements. Even if you receive a rejection email, 
                    we'll reach out if a new internship position matches your profile. 
                    </p>

                    <div className="button-container">
                        <a href="/login">
                            {loginState.loggedIn ? 
                                (null) : 
                                (<div className="button">
                                    <p>Apply now</p>
                                </div>)
                            }
                        </a>
                    </div>
                    
                </div>
                <div className="how-it-works-1-partnership">
                    <img src={HOWITWORKS3} alt="How it works 3" className='image3-styling'/>
                </div>
                </div>
            </div>

            <div className="cta-container">
                <div className="cta-text">Hear from students like you</div>
                <div className="button-container">
                    <a href="/login">
                        {loginState.loggedIn ? 
                            (null) : 
                            (<div className="button">
                                <p>Apply now</p>
                            </div>)
                        }
                    </a>
                </div>
            </div>
            <div className="carousel">
                <Swiper
                spaceBetween={20}
                slidesPerView={isMobile ? 1: 3}
                modules={[Pagination]}
                pagination={{ clickable: true }}
                style={{ maxWidth: '1500px', margin: 'auto', paddingBottom: '70px' }}
                >
                <SwiperSlide>
                    <div className="card">
                    <div className="card-top">
                        <img
                        src={Review4}
                        alt="Brandon Le Photo"
                        className="card-image-styling"
                        />
                    </div>
                    <div className="card-content">
                        <h3>Brandon Le</h3>
                        <p className="title">Computer Science, Junior</p>
                        <p className="quote">
                        “ The easier, fastest and most efficient way to find internships. Cheetah apply or 
                        regular apply - either way the best part is Intern Guys 
                        being an intern recruitment agency for startups, brings positions to you! 
                        And btw guaranteed hear back in 2 days !!! ”
                        </p>
                    </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="card">
                    <div className="card-top">
                        <img
                        src={Review1}
                        alt="Heather Gardner Photo"
                        className="card-image-styling"
                        />
                    </div>
                    <div className="card-content">
                        <h3>Heather Gardner</h3>
                        <p className="title">Business Admin, Senior</p>
                        <p className="quote">
                        “ The easiest and most fun platform to land an internship! 
                        As a student-started platform, Intern Guys knows 
                        exactly what helps you find interships. A must-try ”
                        </p>
                    </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="card">
                    <div className="card-top">
                        <img
                        src={Review3}
                        alt="Daniella Park Photo"
                        className="card-image-styling"
                        />
                    </div>
                    <div className="card-content">
                        <h3>Daniella Park</h3>
                        <p className="title">Cognitive Science, Freshman</p>
                        <p className="quote">
                        “ Great website - love their UI! They have a fun, light vibe 
                        to their website and I really vibe to it. Getting an internship 
                        doesnt have to be so serious, rather can be fun, and Intern Guys plays that card really well ”
                        </p>
                    </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="card">
                    <div className="card-top">
                        <img
                        src={Review2}
                        alt="Curtis Hawkins Founder Photo"
                        className="card-image-styling"
                        />
                    </div>
                    <div className="card-content">
                        <h3>Curtis Hawkins</h3>
                        <p className="title">Computer Science, Junior</p>
                        <p className="quote">
                        “ Intern Guys got some great internship companies to work for! If this is your 
                        first time trying for an internship - you should go with the startups 
                        Intern Guys has here! Great places to learn! ”
                        </p>
                    </div>
                    </div>
                </SwiperSlide>
                </Swiper>
            </div>

            {/* FAQ Section */}
            <div className="faq-section">
            {/* <h2 style={{ fontWeight: 'bold', fontSize: '35px', marginBottom: '30px' }}>FAQ</h2> */}
            {/* {faqs.map((faq, index) => (
                <div className="faq-item" key={index}>
                    <button 
                        className="faq-question"
                        onClick={() => toggleFAQ(index)}
                    >
                        {faq.question}
                        <span className="arrow">{openIndex === index ? '▼' : '▲'}</span>
                    </button>
                    <div 
                        className="faq-answer"
                        ref={el => faqRefs.current[index] = el}
                    >
                        {faq.answer}
                    </div>
                </div>
            ))} */}
        </div>

            <div className="component_five_container">
                <h1 className="calendly_header" id="component_five">Need advice?</h1>
                <div className="calendly_subtext">
                Book a free call with us today!
                </div>
                <div className="calendly_container">
                <div className="calendly_inner_container">
                    <InlineWidget
                    styles={{
                        position: 'absolute',
                        top: '0',
                        bottom: '0',
                        width: '100%',
                    }}
                    pageSettings={{
                        backgroundColor: 'fff',
                        hideEventTypeDetails: false,
                        hideLandingPageDetails: false,
                        primaryColor: '00a2ff',
                        textColor: '000',
                    }}
                    url="https://calendly.com/internguys/intern-guys-linkedin-1-1-consultation"
                    />
                </div>
                </div>
            </div>

            <div className="internships">
                <h3>Find Your Dream Internship</h3>
                <div className="jobs-container">
                    {internships.map((internship) => (
                        <div className="job-item" onClick={previewRedirect}>
                            <img src={require(`../../images/homepage/${internship.image}.svg`)} alt="Internship company logo" />
                            <div className="text-container">
                                <div className="bottom-container">
                                <h5>{internship.position}</h5>
                                    <div className="bottom">
                                        <h6>{internship.company}</h6>
                                        <p>{internship.location}</p>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    ))}         
                </div>
                <h4>Internships are waiting for you!</h4>
                <a href="/login">
                <div>
                    {loginState.loggedIn ? 
                        (null) : 
                        (<div className="button">
                            <p>Sign Up Now</p>
                        </div>)}
                </div>
                </a>
            </div>
            <Footer />
            {isVisible && (
                <button
                    onClick={scrollToTop}
                    className="scroll-to-top-button"
                >
                    <div className="button-content">
                        <svg
                            width="52"
                            height="52"
                            viewBox="0 0 52 52"
                        >
                            <circle
                                cx="26"
                                cy="26"
                                r="25"
                                fill="none"
                                stroke="#ffffff"
                                strokeWidth="2"
                                strokeDasharray="157"
                                strokeDashoffset={157 - (157 * scrollProgress) / 100}
                            />
                        </svg>
                        <span className="arrow">↑</span>
                    </div>
                </button>
            )}
        </div>
    )
}

export default Homepage;