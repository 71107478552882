import './styles.css';
const Blogcard = ({ title, date, description, image, onClick, author }) => {
  return (
    <div className="blog-card bg-white mobile-width mobile-height blog-card-md mobile-flex">
      <div className="blog-card-md-section h-50 flex p-30">
        <img
          src={image}
          alt="blog-img"
          className="rounded-lg object-fit-contain w-full h-full"
        />
      </div>
      <div className="flex-col mobile-card blog-card-md-section p-30 section-p h-50 pt-0">
        <h1 className="font-bold card-title md-title mb-0">{title}</h1>
        <p className="mb-0 font-bold text-blue">by {author} </p>
        <p className="mb-0 font-bold">Published {date}</p>

        <p
          dangerouslySetInnerHTML={{ __html: description }}
          className="card-description md-desc mobile-desc"
        />
        <div className="flex-row align-center justify-center mt-auto md-button">
          <button
            className="read-more-button mobile-button mt-auto"
            onClick={onClick}
          >
            {' '}
            READ MORE
          </button>
        </div>
      </div>
      <div></div>
    </div>
  );
};
export default Blogcard;
