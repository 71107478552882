import $ from "jquery";
import { storeToken } from "./loginState";
const linkedInAppId = "77828456px2dta";

function onSignInLinkedIn(response) {
  const code = response.code;
  console.log(code)
  // const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
  const linkedinAuthEndPoint = process.env.REACT_APP_LINKEDIN_AUTH_ENDPOINT;

  $.ajaxSetup({
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  $.post(
    `${linkedinAuthEndPoint}`,
    JSON.stringify({ idTokenString: code }),
    (data) => {
      localStorage.setItem("userEmail","placeholderValue");
      console.log(data);
      storeToken(data);
      //Inlucde the token in all api calls
      window.location.replace("/");
    }
  );
}

export { onSignInLinkedIn, linkedInAppId };
