import "./style.css";
import React from "react";
import {onSignInGoogle,onLoginInGoogle} from "../../authetication/GoogleAuth";
import {FaLinkedinIn} from "react-icons/fa";
import { LinkedIn } from "react-linkedin-login-oauth2";
import {
  onSignInLinkedIn,
  linkedInAppId,
} from "../../authetication/LinkedInAuth";

const ExternalAuth = ({isSignup}) => {
  console.log("prop:",isSignup);
  return (
    <form className="external_form">
      <div className="external_icon_container">
        
        <GoogleSignIn className="google_sign_up" isSignup={isSignup}/>
        
        <LinkedIn
          clientId={linkedInAppId}
          onSuccess={onSignInLinkedIn}
          scope="r_liteprofile,r_emailaddress"
          redirectUri="http://www.internguys.com/linkedin-login"
          className="linkedin__btn"
        >
          <div className="linkedin__btn">
            <FaLinkedinIn className="linkedin__icon"/>
          </div>
        </LinkedIn>
      </div>
    </form>
  );
};

const GOOGLE_BUTTON_ID = "google-sign-in";
class GoogleSignIn extends React.Component {
  componentDidMount(){
    this.renderGoogleSignin();
  }
  componentDidUpdate(prevProps) {
    if (this.props.isSignup !== prevProps.isSignup) {
      this.renderGoogleSignin();
    }
  }
  renderGoogleSignin() {
    const callbackFunction = this.props.isSignup ? onSignInGoogle : onLoginInGoogle;
    window.gapi.signin2.render(GOOGLE_BUTTON_ID, {
      onsuccess: callbackFunction,
    });
  }

  render() {
    return <div id={GOOGLE_BUTTON_ID} className = "google_btn"  />;
  }
}

export default ExternalAuth;
