import React, { useState } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import Resume from "../../images/resume.png";

const MockInterviewer = () => {
    const [position, setPosition] = useState("");
    const [jobDescription, setJobDescription] = useState("");
    const [resume, setResume] = useState(null);
    const [fileName, setFileName] = useState("");
    let navigate = useNavigate();

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        setResume(e.target.value);

        reader.onload = function(event) {
            localStorage.setItem("resume", event.target.result);
            setResume(event.target.result);  // Set the resume state to the file's content
        };

        reader.readAsDataURL(file);
        setFileName(file ? file.name : "");  // Update the fileName state
    };

    const handlePositionChange = (e) => {
        setPosition(e.target.value);
        localStorage.setItem("position", e.target.value);
    };

    const handleJobDescriptionChange = (e) => {
        setJobDescription(e.target.value);
        localStorage.setItem("jobDescription", e.target.value);
    };

    const handleGetStarted = () => {
        navigate("/mockInterviewerZoom");
    };

    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
    };

    const SectionTitle = ({ text, subtitle }) => (
        <div className="section-title-container">
            <h1 className="section-title">{text}</h1>
            <p className="section-subtitle">{subtitle}</p>
        </div>
    );

    const isFormComplete = position && resume;

    return (
        <div className="contact-page">
            <SectionTitle
                text="AI Mock Interviewer"
                subtitle="Meet Nora, our AI Mock Interviewer! Upload your resume, enter a position, and provide a job description. Nora will simulate a realistic interview to streamline your prep :)"
            />
            <div className="form-container">
                <div className="form-section left-section">
                    <div className="form-group file-upload" onClick={triggerFileInput}>
                        <input
                            type="file"
                            id="fileInput"
                            className="form-control"
                            onChange={handleFileChange}
                            accept=".pdf,.doc,.docx"
                        />
                        <div className="file-upload-content">
                            <img src={Resume} alt="PDF Icon" className="pdf-icon" />
                            {fileName ? (
                                <p className="file-name">{fileName}</p>
                            ) : (
                                <>
                                    <p>Click to upload your resume here *</p>
                                    <p className="file-size">up to 10 MB - pdf or .docx</p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="right-sections">
                    <div className="form-section">
                        <div className="form-group">
                            <label>
                                Position <span className="required-asterisk">*</span>
                            </label>
                            <input
                                className="form-control"
                                value={position}
                                onChange={handlePositionChange}
                                placeholder="ex. UX Design Intern at Google"
                            />
                        </div>
                    </div>
                    <div className="form-section">
                        <div className="form-group">
                            <label>Job Description</label>
                            <textarea
                                className="form-control"
                                value={jobDescription}
                                onChange={handleJobDescriptionChange}
                                placeholder="Enter the position's job description - gives our AI the ultimate context!"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <button
                className={`btn ${isFormComplete ? 'btn-primary' : 'btn-disabled'}`}
                onClick={handleGetStarted}
                disabled={!isFormComplete}
            >
                Get Started
            </button>
        </div>
    );
};

export default MockInterviewer;
