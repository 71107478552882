import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import Images from "../../Images";
import Navlist from "./navList";
import { getToken } from '../../authetication/loginState';
import "./Navbar.css";

function Navbar({ loginState }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [controller, setController] = useState(false);
  const [open, setOpen] = useState(false);
  const [mobileState, setMobileState] = useState(false);

  useEffect(() => {
    setController(false);
  }, [location]);

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      setMobileState(window.innerWidth <= 850);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getNavbarClass = () => {
    return (location.pathname === '/' || location.pathname === '/mockInterviewer' || location.pathname === '/mockInterviewerZoom') ? 'navbar home' : 'navbar other';
  };

  const isLoggedIn = getToken();

  return (
    <nav className={getNavbarClass()}>
      <div className="navbar__logo">
        <img
          src={(location.pathname === '/' || location.pathname === '/mockInterviewer' || location.pathname === '/mockInterviewerZoom') ? Images.IG_LOGO2 : Images.IG_LOGO}
          alt="Intern guys"
          onClick={() => navigate("/")}
          className={open ? "noSmall" : ""}
        />
      </div>

      <div
        className="navbar__navController"
        onClick={() => setOpen(!open)}
      >
        <div className="navbar__bar" />
        <div className="navbar__bar" />
        <div className="navbar__bar" />
      </div>

      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        anchor={isLoggedIn ? 'right' : 'top'}
        className={`navbar__nav ${open ? "open" : "navbar_noDisplay"}`}
      >
        <Navlist loginState={loginState} open={open} setOpen={setOpen} mobileState={mobileState} />
      </Drawer>
      <div className={`navbar__nav ${open ? "noSmall" : "navbar__noDisplay"}`}>
        <Navlist loginState={loginState} open={open} setOpen={setOpen} mobileState={mobileState} />
      </div>
    </nav>
  );
}

export default Navbar;