import React, { useState } from "react";
import "./style.css";
import { useLocation } from 'react-router-dom';
import downArrow from "../../assets/icons/downArrow.svg";
import {Select, MenuItem } from '@material-ui/core'

const MockInterviewerFeedback = () => {
  const location = useLocation();
  const { aiResponse, history, timerFeedback } = location.state || {};
  const [activeSection, setActiveSection] = useState("overview");
  console.log("HANNAN aiResponse: ", aiResponse);
  const jsonString = aiResponse.replace(/```json\n|\n```/g, '');
  const feedbackJson = JSON.parse(jsonString);
  console.log("HANNAN feedbackJson: ", feedbackJson);
  const [filter, setFilter] = useState("transcript");

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };
  
  const DateComponent = () => {
    const today = new Date();
    return formatDate(today);
  };

  return (
    <div className='main'>
      <div className="side-menu">
        <ul>
          <li onClick={() => setActiveSection("overview")}>Overview</li>
          <li onClick={() => setActiveSection("technicalSkills")}>Technical Skills</li>
          <li onClick={() => setActiveSection("softSkills")}>Soft Skills</li>
          <li onClick={() => setActiveSection("transcript")}>Transcript</li>
          <li onClick={() => setActiveSection("nextSteps")}>Next Steps</li>
        </ul>
      </div>
      <div className="content">
        <h2 className="title-heading">Your Mock Interview Report</h2>
        <h3 className="sub-title">{feedbackJson?.position}</h3>
        <h3 className="sub-title">{`${timerFeedback} - ${DateComponent()}`}</h3>

        <div className="select-section">
          <Select 
            value={activeSection}
            onChange={(e) => setActiveSection(e.target.value)}
            disableUnderline
            id="select-style"
          >
            <MenuItem value="overview">Overview</MenuItem>
            <MenuItem value="technicalSkills">Technical Skills</MenuItem>
            <MenuItem value="softSkills">Soft Skills</MenuItem>
            <MenuItem value="transcript">Transcript</MenuItem>
            <MenuItem value="nextSteps">Next Steps</MenuItem>
          </Select>
        </div>

        {activeSection === "overview" && (
          <div className="feedback-section">
            <div className="overview-section">
              <h3>Overview</h3>
              <div className="summary-section">
                <h4>Summary</h4>
                <p>{feedbackJson?.overallSummary}</p>
              </div>
              <div className="skills-section">
                <div className="technical-skills-summary">
                  <h4>Technical Skills</h4>
                  <p>{feedbackJson?.technicalSkillsSummary}</p>
                </div>
                <div className="soft-skills-summary">
                  <h4>Soft Skills</h4>
                  <p>{feedbackJson?.softSkillsSummary}</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeSection === "technicalSkills" && (
          <div className="technical-section">
            <h3>Technical Skills</h3>
            <div className="section">
            <h4>Technical Summary</h4>
            <p>{feedbackJson?.technicalSummary}</p>
            </div>

            <div className="section">
              <h4>Technical Sentiment</h4>
              <p>{feedbackJson?.technicalSentiment}</p>
            </div>

            <div className="section">
              <h4>Technical Question(s) We Asked</h4>
              {feedbackJson?.question
                .filter(qa => qa.categorization.toLowerCase() === "technical")
                .map((qa, index) => (
                  <div key={index} className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed technical-button" data-bs-toggle="collapse" data-bs-target={`#${index}`}>
                      <div className="d-flex justify-content-between">
                        <span className="text-left">
                        {qa?.question}
                        </span>
                        <img src={downArrow} className="ml-2" />
                      </div>
                    </button>
                  </h2>
                  <div id={`${index}`} className="accordion-collapse collapse">
                    <div className="accordion-body  contact__accordian-body">
                    <p id="dropdown-title">Your Answer: </p>
                    <p id = "dropdown-paragraph">{qa?.candidateAnswer}</p>
                    <p id="dropdown-title">Our Analysis: </p>
                    <p id = "dropdown-paragraph">{qa?.analysis}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="section">
              <h4>Interview Suggestions</h4>
              <p>{feedbackJson?.technicalInterviewSuggestions}</p>
            </div>

            <div className="section">
              <h4>Resume/Background Suggestions</h4>
              <p>{feedbackJson?.technicalResumeSuggestions}</p>
            </div>

          </div>
        )}

        {activeSection === "softSkills" && (
          <div className="soft-skill-section">
            <h3>Soft Skills</h3>
            <div className="section">
              <h4>Summary</h4>
              <p>{feedbackJson?.softSkillsSummary}</p>
            </div>
            <div className="section">
              <h4>Behavioral Question(s) We Asked</h4>
              {feedbackJson?.question
                .filter(qa => qa.categorization.toLowerCase() === "soft skill")
                .map((qa, index) => (
                  <div key={index} className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed technical-button" data-bs-toggle="collapse" data-bs-target={`#${index}`}>
                      <div className="d-flex justify-content-between">
                        <span className="text-left">
                        {qa?.question}
                        </span>
                        <img src={downArrow} className="ml-2" />
                      </div>
                    </button>
                  </h2>
                  <div id={`${index}`} className="accordion-collapse collapse">
                    <div className="accordion-body  contact__accordian-body">
                    <p id="dropdown-title">Your Answer: </p>
                    <p id = "dropdown-paragraph">{qa?.candidateAnswer}</p>
                    <p id="dropdown-title">Our Analysis: </p>
                    <p id = "dropdown-paragraph">{qa?.analysis}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="section">
              <h4>Communication Feedback</h4>
              <p>{feedbackJson?.communication}</p>
            </div>

            <div className="section">
              <h4>Time Management Feedback</h4>
              <p>{feedbackJson?.timeManagement}</p>
            </div>
          </div>
        )}

        {activeSection === "transcript" && (
          <div className="message-history-feedback">
           <div className="transcript-heading">
            <div><h3 className="transcript-title">Transcript</h3></div>
            <div className="filter-section">
              <Select 
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                disableUnderline
                id="filter-style"
              >
                <MenuItem value="transcript">Transcript</MenuItem>
              </Select>
            </div>
          </div>

          {
            filter === "transcript" && (
              <div className="transcript-style">
              {history.map((msg, index) => (
                msg.message !== "first" && msg.message !== "last" && msg.role == "Assistant" && (
                  <div key={index} >
                    <div><strong>Nora:</strong> {msg.message}</div>
                    <br />
                  </div>
                ) ||
                msg.message !== "first" && msg.message !== "last" && msg.role == "User" && (
                  <div key={index} >
                    <div><strong>You:</strong> {msg.message}</div>
                    <br />
                  </div>
                )
              ))}
              </div>
            )
          }
        </div>
        )}

        {activeSection === "nextSteps" && (
          <div className="next-steps-section">
            <h4>Our Recommendations</h4>
            <div className="soft-section-border">
              <p>{feedbackJson?.nextSteps}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MockInterviewerFeedback;